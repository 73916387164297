import { FC, ReactNode, createContext, useEffect, useState, useContext } from 'react';
import { ReactReduxContext } from 'react-redux'
import { User } from 'src/models/user';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import { loginPromise, initialize, logoutAuth, dispachtLogin, initialState as initialAuthState } from '../slices/authSlice';
import { useDispatch, useSelector } from '../redux/store';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}


const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.authentication);

  useEffect(() => {

    dispatch(initialize());
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    let user = await loginPromise(email, password)

    dispatch(dispachtLogin(user));
  };

  const logout = (): void => {
    dispatch(logoutAuth());
  };

  // const register = async (
  //   email: string,
  //   name: string,
  //   password: string
  // ): Promise<void> => {
  //   const response = await axios.post<{ accessToken: string; user: User }>(
  //     '/api/account/register',
  //     {
  //       email,
  //       name,
  //       password
  //     }
  //   );
  //   const { accessToken, user } = response.data;

  //   window.localStorage.setItem('accessToken', accessToken);
  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user
  //     }
  //   });
  // };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
        // register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
