import { useRef, useState, useEffect } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled
} from '@mui/material';
import Text from 'src/components/Text';

import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const ImageWrapper = styled('img')(
  () => `
        width: 30px;
        margin: 3px;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
  };

  const setDefaulLanguage = () => {
    switchLanguage({ lng: 'es' });
  }

  useEffect(() => {
    setDefaulLanguage();
  }, []);


  return (
    <>
    </>
  );
}

export default LanguageSwitcher;
