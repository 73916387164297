import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Dashboards

const Dashboard = Loader(
    lazy(() => import('src/content/admin/Dashboard'))
);

const Events = Loader(lazy(() => import('src/content/admin/Event')));
const Assigments = Loader(lazy(() => import('src/content/admin/Assigment')));
const Report = Loader(lazy(() => import('src/content/admin/Reports')));
const Notification = Loader(lazy(() => import('src/content/admin/Notification')));
const Banner = Loader(lazy(() => import('src/content/admin/Banner')));
const Users = Loader(lazy(() => import('src/content/admin/User')));

const adminRoutes = [
    {
        path: '/',
        element: <Navigate to="dashboard" replace />
    },
    {
        path: 'dashboard',
        element: <Dashboard />
    },
    {
        path: 'events',
        element: <Events />
    },
    {
        path: 'reports',
        element: <Report />
    },
    {
        path: 'assignments',
        element: <Assigments />
    },
    {
        path: 'notifications',
        element: <Notification />
    },
    // {
    //     path: 'banners',
    //     element: <Banner />
    // },
    {
        path: 'users',
        element: <Users />
    },
];

export default adminRoutes;
