import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { MenuItems } from './interfaces';

const menuItemsOperator: MenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Home',
                icon: HomeIcon,
                link: '/operator/dashboard'
            },
            {
                name: 'Events',
                icon: EventAvailableIcon,
                link: '/operator/events'
            },
            {
                name: 'Reports',
                icon: AssessmentIcon,
                link: '/operator/reports'
            },
            {
                name: 'Assignments',
                icon: AssignmentIndIcon,
                link: '/operator/assignments'
            },
            {
                name: 'Notifications',
                icon: NotificationsActiveIcon,
                link: '/operator/notifications'
            },

        ]
    }
];

export default menuItemsOperator;
