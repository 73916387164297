import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authenticationReducer } from '../../slices/authSlice';
import { reducer as eventReducer } from '../../slices/eventSlice';
import { reducer as userReducer } from '../../slices/userSlice';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  event: eventReducer,
  user: userReducer
});

export default rootReducer;
