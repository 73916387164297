import { Suspense, lazy } from 'react';

import SuspenseLoader from '../components/SuspenseLoader';
import { Navigate } from 'react-router-dom';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const FormEvent = Loader(
    lazy(() => import('src/content/event/eventForm'))
);

const eventRoutes = [
    {
        path: '/',
        element: <Navigate to="form" replace />
    },
    {
        path: 'form',
        element: <FormEvent />
    }
];

export default eventRoutes;
