import {
    Divider,
    Container,
    Typography
} from '@mui/material';

import FormEvent from 'src/components/Event/form';
import { useTranslation } from 'react-i18next';

const EventForm = () => {
    const { t }: { t: any } = useTranslation();

    let dataForm = {
        id: '',
        description: '',
        kilometer: '',
        sector: '',
        commune: '',
        orientation: '',
        channelCode: 'WEB_SELF_SERVICE',
        classificationId: '',
        dateEvent: '',
        assignedToId: 1,
        submit: null,
        category: '',
        subcategory: '',
        statusCode: '',
        realAddress: '',
        email: '',
        fullName: '',
        phone: '',
        rut: ''
    };

    return (
        <Container maxWidth="md" style={{ backgroundColor: 'white', border: '1px solid lightgray', boxShadow: '4px 4px 16px lightgrey' }}>
            <img style={{ margin: "0 auto", display: "block"}} src="/static/logo.png" width={240} height={120} />
            <Typography variant="h1" gutterBottom textAlign={'center'}>
                Contacto
            </Typography>
            <Divider />
            <FormEvent data={dataForm} titleButtonAction={'Crear Evento'} messageOK='El evento fue creado con éxito. Te contactaremos a la brevedad' isEdit={false} showButtonAttachment={true} />
            <Divider />
            <br />
            {/* <Box my={2} sx={{ float: 'right', clear: 'both' }}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight="bold"
                >
                    {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Link component={RouterLink} to="/user/register">
                    <b>{t('Sign up here')}</b>
                </Link>
            </Box>
            <Box my={2} sx={{ float: 'right', clear: 'both' }}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight="bold"
                >
                    {t('Ya tienes una cuenta?')}
                </Typography>{' '}
                <Link component={RouterLink} to="/user/login">
                    <b>{t('Ingresa aquí')}</b>
                </Link>
            </Box> */}
        </Container>
    )
}

export default EventForm;