import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface ITextEditorProps {
    value: string;
    disabled:boolean;
    setFieldValue: (val: string) => void;
}

export const TextEditor = ({ value, setFieldValue }: ITextEditorProps) => {
    const prepareDraft = (value: string) => {
        const draft = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [editorState, setEditorState] = useState(
        value ? prepareDraft(value) : EditorState.createEmpty()
    );

    const onEditorStateChange = (editorState: EditorState) => {
        let forFormik = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        if (forFormik == '<p></p>' || forFormik == "<p></p>\n") {
            forFormik = '';
        }

        setFieldValue(forFormik);
        setEditorState(editorState);
    };
    return (
        <div style={{border: '1px solid lightgray'}}>
            <Editor
                editorState={editorState}
                wrapperClassName="custom-wrapper"
                editorClassName="custom-editor"
                onEditorStateChange={onEditorStateChange}
                
            />
        </div>
    );
};