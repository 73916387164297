import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Dashboards

const DashboardOperator = Loader(
    lazy(() => import('src/content/inspector/Dashboard'))
);

const Events = Loader(lazy(() => import('src/content/inspector/Event')));
const Report = Loader(lazy(() => import('src/content/inspector/Reports')));
const Notification = Loader(lazy(() => import('src/content/inspector/Notification')));

const inspectorRoutes = [
    {
        path: '/',
        element: <Navigate to="dashboard" replace />
    },
    {
        path: 'dashboard',
        element: <DashboardOperator />
    },
    {
        path: 'events',
        element: <Events />
    },
    {
        path: 'reports',
        element: <Report />
    },
];

export default inspectorRoutes;
