import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import esJSON from './translations/es';

const resources = {
  es: { translation: esJSON },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'es',
    fallbackLng: 'es',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
