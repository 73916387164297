import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PeopleIcon from '@mui/icons-material/People';
import { MenuItems } from './interfaces';

const menuItemsAdmin: MenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Home',
                icon: HomeIcon,
                link: '/admin/dashboard'
            },
            {
                name: 'Events',
                icon: EventAvailableIcon,
                link: '/admin/events'
            },
            {
                name: 'Reports',
                icon: AssessmentIcon,
                link: '/admin/reports'
            },
            {
                name: 'Assignments',
                icon: AssignmentIndIcon,
                link: '/admin/assignments'
            },
            {
                name: 'Notifications',
                icon: NotificationsActiveIcon,
                link: '/admin/notifications'
            },
            // {
            //     name: 'Banners',
            //     icon: FileUploadIcon,
            //     link: '/admin/banners'
            // },
            {
                name: 'Users',
                icon: PeopleIcon,
                link: '/admin/users'
            },

        ]
    }
];

export default menuItemsAdmin;
