// import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { routerDriver, routerOperator, routerDefault, routerOperatorFiscal,routerInspector,routerAdmin } from './router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { useSelector } from './redux/store';

function App() {
    const auth = useAuth();
    let routes = null;

    if (auth.user !== null) {
        switch (auth.user.role) {
            case 'OPERATOR':
                routes = useRoutes(routerOperator);
                break;
            case 'INSPECTOR':
                routes = useRoutes(routerInspector);
                break;
            case 'SUPERVISOR':
                routes = useRoutes(routerOperatorFiscal);
                break;
            case 'DRIVER':
                routes = useRoutes(routerDriver);
                break;
            case 'ADMIN':
                routes = useRoutes(routerAdmin);
                break;
            default:
                routes = useRoutes(routerDefault)
                break;
        }
    } else {
        routes = useRoutes(routerDefault)
    }

    const content = routes;

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <CssBaseline />
                    {auth.isInitialized ? content : <AppInit />}
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
