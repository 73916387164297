import { User, UserRole } from '../models/user';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InitializeAction, LoginAction, LogoutAction, RegisterAction } from '../redux/actions/authAction';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import type { AppThunk } from '../redux/store';
import { useSelector } from '../redux/store';

interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}


export const initialState: AuthState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null
};

const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                user
            };
        },
        LOGIN: (state: AuthState, action: LoginAction): AuthState => {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        },
        LOGOUT: (state: AuthState): AuthState => ({
            ...state,
            isAuthenticated: false,
            user: null
        }),
        REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
    }
});

export const reducer = slice.reducer;

const setSession = (accessToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};


export const initialize =
    (): AppThunk =>
        async (dispatch, getState: any): Promise<void> => {
            const state = getState();

            try {
                const accessToken = window.localStorage.getItem('accessToken');

                //quito linea verify(accessToken, JWT_SECRET) , ya que es necesaro
                //ver como proveer un secret key
                if (accessToken) {
                    setSession(accessToken);

                    let user = state.authentication.user;

                    if (user === null) {
                        user = window.localStorage.getItem('user');
                        user = JSON.parse(user)
                    }

                    dispatch(slice.actions.INITIALIZE({
                        isAuthenticated: true,
                        user
                    }));
                } else {
                    dispatch(slice.actions.INITIALIZE({
                        isAuthenticated: false,
                        user: null
                    }))
                }
            } catch (err) {
                console.error(err);
                dispatch(slice.actions.INITIALIZE({
                    isAuthenticated: false,
                    user: null
                }))

            }
        };

export const loginPromise = async (email: string, password: string): Promise<User> => {

    const response = await axios.post<{ user: User, headers: any }>(
        '/api/auth',
        {
            email: email,
            password: password,
            providerId: "email",
            tokenId: ""
        });

    let authorization = response.headers.hasOwnProperty('authorization') ? response.headers.authorization : null;

    if (authorization === null) {
        throw new Error("Missing token from response");
    }

    let accessToken = authorization.split(" ")
    let profile = null;
    let userData = null;
    let rolUser = null;
    setSession(accessToken[1]);

    profile = await axios.get('/api/user/me');
    rolUser = profile.data.userProfileDto.groups[0].code as UserRole


    userData = {
        id: profile.data.userProfileDto.id,
        avatar: "/static/images/avatar/4.jpg",
        email: profile.data.email,
        name: profile.data.userProfileDto.nickName,
        jobtitle: profile.data.userProfileDto.genre,
        username: profile.data.email,
        location: "Argentina",
        role: rolUser,
        posts: null,
        coverImg: "/static/images/avatar/4.jpg",
        followers: null,
        description: "user testing"
    }

    localStorage.setItem('user', JSON.stringify(userData));

    return userData;
}

export const dispachtLogin =
    (user: User): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.LOGIN({
                user: user
            }))
        };

export const logoutAuth =
    (): AppThunk =>
        async (dispatch): Promise<void> => {
            setSession(null);
            localStorage.setItem('user', null);
            dispatch(slice.actions.LOGOUT())
        };

export const register = (email: string, name: string, password: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const response = await axios.post<{ accessToken: string; user: User }>(
            '/api/account/register',
            {
                email,
                name,
                password
            }
        );
        const { accessToken, user } = response.data;

        window.localStorage.setItem('accessToken', accessToken);

        dispatch(slice.actions.REGISTER({
            user
        }))

    };

export const forgottenPasswordPromise = async (email: string): Promise<void> => {

    await axios.get<{ url: string, headers: any }>(`/api/profile/forgotten/${email}`);
    // await axios.get<{ url: string, headers: any }>(`/api/profile/forgotten/${email}`,{
    //     headers: {
    //         "authorization" : "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhNjk2N2ViZi01ZWRkLTRiNDQtODE3Yy0wYjZlNDNkNmMzYjMiLCJyb2xlcyI6W10sImV4cCI6MTY0NTI3MDU2MX0.8iJ_SucXjQIqVqZeZDnsg-q3PeZ4oaxFwSaHbe0rRf0ycY7loPDu-Oc8gXV9TAPRJaSwJxZmPAnJYhioZEcItA"
    //     }
    // });
}

export default slice;